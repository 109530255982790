//grid mixin
@mixin grid($columns: 2, $tag: "div") {
  #{$tag} {
    width: 100% / $columns;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    float: left;
  }
  &:after {
    display: table;
    content: " ";
    clear: both;
  }
}

// grid mixin

//globals
body, html {
  padding: 0;
  margin: 0;
  /* font-family: canada-type-gibson, sans-serif; */
}
img {
  max-width: 100%;
}

//nav

nav {
  background: black;
  color: white;
  .wrapper {
    @include grid(2);
    .logo {
      height: 76px;
      padding: 30px;
      @media (max-width: 950px) {
        width: 40%;
        height: 91px;
        padding: 20px;
      }
      @media (max-width: 458px) {
        height: 67px;
        padding: 10px;
      }
      a {
        display: block;
        height: 100%;
        img {
          height: 100%;
          display: inline-block;
        }
      }
    }

    .grid {
      border-left: 1px solid white;
      padding: 20px 20px 20px 0;
      width: auto;
      float: right;
      min-width: 600px;
      @include grid(2);
      @media (max-width: 1250px) {
        @include grid(1);
        min-width: 1px;
        font-size: 12px;
        font-weight: normal;
        border: 0;
        text-align: right
      }
      @media (max-width: 950px) {
        width: 60%;
      }
      @media (max-width: 458px) {
        padding: 10px 10px 10px 0;

      }

      font-family: arial;
      letter-spacing: 1px;
      font-weight: bold;
      font-size: 16px;

      .open {
        font-size: 24px;
        a {
          color: white;
          text-decoration: none;
        }
        @media (max-width: 950px) {
          font-size: 16px;
          padding-bottom: 5px;
        }
        @media (max-width: 458px) {
          font-size: 12px;
        }
      }
      .times {
        @media (max-width: 950px) {
          width: 100%;
        }
      }
    }
  }

  .open {
    padding-left: 25px;
  }
}

//hero

div.hero {
  background-image: url(../img/hero.jpg);
  background-position: center;
  background-size: cover;
  height: 70vh;

  position: relative;
  @media (max-width: 950px) {
    height: 400px;
  }
  @media (max-width: 950px) {
    background-position: left;
  }
  img {
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    margin: auto;
    border-bottom: 5px solid #ff00cc;
    @media (max-width: 450px) {
      width: calc(100% - 20px);
    }
  }
}

//upto 70

div.uptp70 {
  background: black;
  padding: 50px 0;
  .wrapper {
    max-width: 1210px;
    margin: auto;
    @include grid(2);
    @media (max-width: 767px) {
      @include grid(1);
    }
  }
  .img {
    @media (min-width: 768px) {
      border-right: 1px solid #ff00cc;
    }
    padding: 20px;
  }
  img {
    margin: auto;
    display: block;
  }

  p {
    font-family: canada-type-gibson, sans-serif;
    font-size: 32px;
    font-weight: 600;
    color: white;
    text-align: center;
    letter-spacing: 1px;
    padding: 70px 20px;
    max-width: 400px;
    margin: auto;
    @media (max-width: 900px) {
      padding: 30px 20px;
    }
  }
}

//brands
div.brands {
  padding: 50px 0;
  background: #EFEFEF;
  p {
    font-family: canada-type-gibson, sans-serif;
    font-size: 48px;
    font-weight: 600;
    color: black;
    max-width: 1210px;
    margin: auto;
    text-align: center;
    padding: 0 20px;
    @media (max-width: 767px) {
      font-size: 28px;
    }
    span {
      color: #ff00cc;
      @media (min-width: 768px) {
        margin-left: 20px;
        margin-right: 20px;
      }
    }
  }
}

//models

div.models {
  .grid {
    @include grid(4);
    @media (max-width: 767px) {
      @include grid(2);
    }
    img {
      width: 100%;
    }
  }
  p {
    background: #ff00cc;
    color: black;
    text-align: center;
    font-family: arial;
    font-size: 32px;
    text-transform: uppercase;
    font-weight: 800;
    margin: -5px 0 0;
    padding: 20px 0;
  }
}

//findus

div.findus {
  .grid {
    @include grid(2);
    @media (max-width: 500px) {
      @include grid(1);
    }
  }

  .information {
    text-align: center;
    background-color: #e8e8e8;
    p {
      font-family: Arial, Helvetica, sans-serif;
      font-size: 32px;
      margin: 50px 0;
    }
    a {
      display: inline-block;
      border: 2px solid black;
      padding: 10px 50px;
      margin: auto;
      width: auto;
      text-decoration: none;
      color: black;
      text-transform: uppercase;
      font-family: canada-type-gibson, sans-serif;
      letter-spacing: 1px;
      font-weight: bold;
      transition: .5s all;
      &:hover {
        background: black;
        color: white;
      }
    }
  }
}

//times

div.times_bot {
  @media (min-width: 768px) {
    padding: 50px 0;
  }
  div.wrap {
    max-width: 1210px;
    margin: auto;
    text-align: center;
    .grid {
      @include grid(3);
      @media (max-width: 767px) {
        @include grid(1);
      }
      text-align: center;
      .info {
        font-family: canada-type-gibson, sans-serif;
        font-size: 48px;
        font-weight: 600;
        color: black;
        text-transform: uppercase;
        @media (max-width: 767px) {
          font-size: 24px;
        }
      }
      .image {
        padding: 35px 0;
      }
    }
  }
}

//footer
footer {
  padding: 50px 0;
  p {
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
    color: #4A4A4A;
  }
}
